import React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';

import MainNavigation from 'src/components/navigation/MainNavigation';
import Logo from 'src/components/Logo';

const useStyles = makeStyles(theme => ({
  root: {},
  logo: () => ({
    height: 60,
    [theme.breakpoints.up('sm')]: {
      height: 120,
    },
  }),
  navigationContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  navigation: ({ inverse }) => ({
    fontWeight: 700,
    color: theme.palette.common[inverse ? 'white' : 'black'],
  }),
  header: () => ({
    height: '110px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      height: '150px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '170px',
    },
  }),
}));

const PageHeader = props => {
  const { inverse = false } = props;
  const styles = useStyles({ inverse });

  return (
    <Container maxWidth={false} className={styles.root}>
      <Box py={[2, 4]} className={styles.header} textAlign="center">
        <Box display="flex" justifyContent="center">
          <Logo className={styles.logo} type={inverse ? 'light' : 'dark'} />
        </Box>
        <Box pt={[2, 4, 6]} className={styles.navigationContainer}>
          <MainNavigation
            linkProps={{
              classes: {
                link: styles.navigation,
              },
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default PageHeader;
