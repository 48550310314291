import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useLocalization } from 'src/providers/LocalizationProvider';

import Box from '@material-ui/core/Box';
import NavigationLink from 'src/components/navigation/NavigationLink';

const useStyles = makeStyles(theme => ({
  root: {
    // marginBottom: theme.spacing(-6),
  },
}));

export default function MainNavigation(props) {
  const { classes = {}, linkProps } = props;
  const { navigation, location } = useLocalization();
  const styles = useStyles();

  return (
    <Box className={clsx(styles.root, classes.root)}>
      {navigation.main.map(page => (
        <NavigationLink
          key={page.id}
          text={page.title}
          url={page.path}
          isActive={location.pathname.includes(page.path)}
          {...linkProps}
        />
      ))}
    </Box>
  );
}
