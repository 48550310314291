import React, { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import get from 'lodash/get';
import MarkdownSection from 'src/components/markdown/MarkdownSection';
import { useMarkdown } from 'src/providers/MarkdownProvider';
import { useLocalization } from 'src/providers/LocalizationProvider';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import SectionNavigation, {
  withAnchorScrolling,
} from 'cl-ui/components/SectionNavigation';

const WaypointySectionNavigation = props => {
  const [isSticky, setIsSticky] = useState(false);
  const onPositionChange = evt => {
    const { currentPosition: curr, previousPosition: prev } = evt;

    if (curr === 'inside' && prev === 'above') {
      setIsSticky(false);
    } else if (curr === 'above' && prev === 'inside') {
      setIsSticky(true);
    }
  };

  return (
    <>
      <Waypoint onPositionChange={onPositionChange}>
        <div></div>
      </Waypoint>
      <SectionNavigation {...props} isSticky={isSticky} />
    </>
  );
};

const AnchorScrollingSectionNavigation = withAnchorScrolling({
  observe: true,
  initialScroll: true,
  updateHash: false,
  offset: 50,
  // fallback: isIE,
  triggerScroll: false,
})(WaypointySectionNavigation);

const MarkdownSectionRef = React.forwardRef((props, ref) => (
  <MarkdownSection {...props} innerRef={ref} />
));

const useStyles = makeStyles(theme => ({
  scrollContainer: {
    overflow: 'none',
  },
  root: ({ focusColor, isMobile }) => ({
    position: 'relative',
    ...(isMobile && { display: 'none' }),
  }),
  sticky: ({ focusColor }) => ({
    position: 'fixed',
    zIndex: 10,
    top: 0,
    left: 0,
    ...(focusColor && {
      borderBottom: `2px solid ${get(theme.palette, focusColor)}`,
    }),
  }),
  item: {
    padding: '14px 0',
  },
}));

const MarkdownSectionsNavigator = ({ focusColor, page }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const { location } = useLocalization();
  const { getSectionsByPage } = useMarkdown();
  const sections = getSectionsByPage(page);
  const classes = useStyles({ focusColor, isMobile });

  return (
    <AnchorScrollingSectionNavigation
      sections={sections}
      showInNavigationFn={section => !section.frontmatter.skipNavigation}
      classes={{
        scrollContainer: classes.scrollContainer,
        root: classes.root,
        sticky: classes.sticky,
        item: classes.item,
      }}
      render={section => {
        return <MarkdownSectionRef {...section} />;
      }}
    />
  );
};

export default MarkdownSectionsNavigator;
