import React from 'react';
import PT from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import get from 'lodash/get';

import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Section from 'cl-ui/components/Section';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
  },
  section: ({ bgColor, color }) => ({
    ...(color && { color: get(theme.palette, color) }),
    ...(bgColor && { backgroundColor: get(theme.palette, bgColor) }),
  }),
}));

export default function MarkdownSection(props) {
  const { frontmatter, slug, body, innerRef } = props;
  const { bgColor = false, color = false, maxWidth } = frontmatter;

  const classes = useStyles({ bgColor, color });

  return (
    <Section className={classes.section}>
      <Container
        maxWidth={maxWidth || 'xl'}
        className={classes.container}
        ref={innerRef}
      >
        <MDXRenderer>{body}</MDXRenderer>
      </Container>
    </Section>
  );
}

export const PropTypes = {
  body: PT.string.isRequired,
};

MarkdownSection.propTypes = PropTypes;
